<template>
<div>
  <div class="box1">
    <div class="box1-title">社区微邻里</div>
    <div class="box1-content">
      这是一款引领社区治理于一体的社区信息服务平台，连接社区、物业、居民三方，提供办事指南、物业管理、智慧党建功能。社区微邻里是大数据时代的“枫桥经验”，秉承着“用科技为居民连接温暖的服务”不仅赋能基层政府，做好民生诉求的收集。同时我们也基于邻家健康服务百姓。赋能基层社区服务中心，最终让平台做“掌心上的便民平台，指尖上的贴心服务”。基于社区微邻里我们在数字医疗方面做了需求跟进。衍生研发了邻家健康APP，服务于社区医院，在中医科普、健康养生、慢病管理领域持续赋能。
    </div>
  </div>
  <div class="box2">
    <div class="box2-img" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/0f265527-02ac-4d72-bda1-9b893cd9b9c5.png')`}"></div>
    <div class="box2-img" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/ea5ed4bf-c2ab-4f27-940f-551265d91143.png')`}"></div>
  </div>
</div>
</template>

<script>
export default {
  name: "ProductFF"
}
</script>

<style lang="scss" scoped>
.box1{
  height: 509px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  &-title{
    font-weight: bold;
    font-size: 40px;
    color: #000000;
    line-height: 56px;
  }
  &-content{
    margin: 0 auto;
    padding-top: 60px;
    max-width: calc(100vw - 484px);

    font-weight: 400;
    font-size: 20px;
    color: #333333;
    line-height: 37px;
    text-align: left;
  }
}

.box2{
  height: 841px;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://minio.xjiot.link/93album/fd5543c2-c68f-4ebd-b993-a34a65b9dab7.png');
  background-size: cover;
  &-img{
    width: 344px;
    height: 100%;
    background-size: cover;
  }
  &-img+&-img{
    margin-left: 60px;
  }
}

</style>
