<template>
<div>
  <div class="box box1">
    <div class="title">项目介绍</div>
    <div class="content">
      <div class="info" v-for="item in infos" :key="item.title">
          <div class="info-left" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}')`}"></div>
          <div class="info-right">
            <div class="info-right-title">
              <div class="info-right-title__m">{{item.title}}</div>
              <div class="info-right-title__s">{{item.subtitle}}</div>
            </div>
            <div class="info-right-content">
              {{item.content}}
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="box2">
    <div class="box2-title">研究领域</div>
    <div class="box2-content">
      <div class="icon" v-for="item in icons" :key="item.img">
        <img class="icon-img" :src="'https://minio.xjiot.link/93album/'+item.img" alt=""/>
        <div class="icon-title">{{item.title}}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ProductZF",
  data(){
    return{
      infos:[
        {
          img:"c59d1009-f5a7-4ddf-a847-f54efad9501d.png",
          title:"骨与关节疾患大数据云计算中心",
          subtitle:"— 新疆医科大学第一附属医院",
          content:"该项目是将人工智能结合大数据的理念运用到骨与关节疾患诊疗服务体系中，搭建新疆地区骨与关节疾患标准化大数据云计算中心，汇聚数字骨科中心示范基地患者医疗数据及基地研究成果数据，将”3D 打印”、\"手术机器人”、智能穿戴设备及 AI 远程辅助诊治等软件系统在云计算中心部署，实现各级区域数字骨科中心示范基地的数据交互。"
        },
        {
          img:"54f997fd-0361-48b3-8d76-07a7070198c2.png",
          title:"腰椎间盘突出症防筛诊治大数据平台",
          subtitle:"— 新疆医科大学第四附属医院自治区中医医院",
          content:"构建腰椎间盘突出症 (LDH) 大数据诊疗平台，运用AI技术，形成基于患者意愿和最佳中医外治法的临床证据研发临床AI辅助决策系统，以提升LDH防控效果、优化并制定中医外治法个性化诊疗策略，形成符合CFDA法规要求可持续更新、国际高水平循证医学证据的LDH中医外治法综合防治方案。"
        },
        {
          img:"55e64a77-514b-47b3-aee6-c402f826c2c7.png",
          title:"宫颈癌防筛诊治大数据平台",
          subtitle:"— 自治区人民医院",
          content:"在宫颈癌早筛预警监测平台基础上利用人工智能辅助技术研发临床决策支持系统依据预警监测平台随访数据库建立数字化模型，通过 AI 技术辅助决策系统优化新疆女性宫颈癌精准防诊治策略，进行诊前宣教、诊中查询、诊后随访，为医生提供多维度、全周期的患者病程数据管理，便于医生了解患者病情，提高服务质量，降低患者失访率并通过智能决策支持系统对新疆地区进行多中心一体化综合诊疗体系推广应用。"
        },
        {
          img:"21f3af39-baeb-4104-8f4c-86378265b335.png",
          title:"中医骨伤大数据辅诊平台",
          subtitle:"— 新疆医科大学第四附属医院自治区中医医院",
          content:"利用大数据和基于 AI 技术构建辩证模型库，搭建基于多源数据智能应用中医骨伤大数据云平台，充分挖掘所整合的中医骨伤医疗数据潜藏的价值，研发高度智能化的中医骨伤大数据辅诊平台，根据 AI 人工智能算法精准辨证，支持智能开方，个性化调方，实现精准用药。针对现有中医队伍知识还处于比较缺乏的基层医疗机构支持决策辅助，为保存宝贵的经验与知识作支撑，为培养后备人才提供丰富的知识借鉴，使医教研协同发展。"
        }
      ],
      icons:[
        {
          img: "0fb1cbb7-10e4-4c12-8ab8-6fb1ef1c8a0d.png",
          title:"骨关节"
        },
        {
          img: "6d6032f0-8ba4-48d3-9046-cf3f618d74d6.png",
          title:"腰椎"
        },
        {
          img: "2a527f4d-edc0-48e2-a909-09046f34db3f.png",
          title:"宫颈"
        },
        {
          img: "e1dd9e14-e9b4-4f12-914b-100e356f4b71.png",
          title:"骨质"
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .box1{
    height: 1497px;

    .content{
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .info{
    height: 207px;
    width: 100%;
    &-left{
      display: inline-block;
      width: 346px;
      height: 100%;
      background-size: cover;
    }
    &-right{
      width: calc(100% - 346px);
      height: 100%;
      display: inline-block;
      padding: 5px 0 24px 40px;
      text-align: left;
      vertical-align: top;
      &-title{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        line-height: 56px;
        &__m{
          font-weight: bold;
          font-size: 26px;
          color: #333333;
        }
        &__s{
          margin-left: 16px;
          font-weight: 500;
          font-size: 20px;
          color: #6D6D6D;
        }
      }
      &-content{
        margin-top: 23px;
        font-weight: 400;
        font-size: 19px;
        color: #333333;
        line-height: 33px;
      }
    }
  }
  .info+.info{
    margin-top: 80px;
  }

  .box2{
    padding: 120px 0;
    width: 100%;
    height: 562px;
    text-align: center;
    background-image: url("https://minio.xjiot.link/93album/6d8ac9ed-2293-4009-9bc6-e8cb44d9b903.png");
    background-size: cover;
    &-title{
      font-weight: bold;
      font-size: 40px;
      color: #FFFFFF;
      line-height: 56px;
      text-align: center;
    }
    &-content{
      margin-top: 60px;
      display: flex;
      justify-content: center;
    }
  }

  .icon{
    width: 235px;
    height: 207px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #FFFFFF;
    background: rgba(255,255,255,0.14);
    box-shadow: 0px 12px 44px 0px rgba(96,0,0,0.15);
    border-radius: 2px;
    &-img{
      width: 80px;
      height: 80px;
      margin: 0 auto;
    }
    &-title{
      font-weight: 500;
      font-size: 30px;
      color: #FFFFFF;
      line-height: 45px;
      margin: 0 auto;
    }
  }
  .icon+.icon{
    margin-left: 80px;
  }
</style>
