import Vue from 'vue'
import Router from 'vue-router'
import Frame from '@/view/frame/index'
import Home from '@/view/home/index'
import About from '@/view/about/index'
import Contact from '@/view/contact/index'
import Product from '@/view/product/index'
import Product00 from '@/view/product/subpages/product00'
import Product01 from '@/view/product/subpages/product01'
import Product02 from '@/view/product/subpages/product02'
import Product04 from '@/view/product/subpages/product04'
import Product05 from '@/view/product/subpages/product05'
import Product10 from '@/view/product/subpages/product10'
import Product11 from '@/view/product/subpages/product11'
import Product12 from '@/view/product/subpages/product12'


Vue.use(Router)

const baseRouters = [
    {
        path: "/",
        name: "/",
        redirect: "/frame/home"
    },

    {
        path: '/frame',
        name: 'frame',
        component: Frame,
        menuName: "frame",
        children: [
            {
                path: "home",
                name: "Home",
                menuName: "首页",
                component: Home
            },
            {
                path: "about",
                name: "About",
                menuName: "关于我们",
                component: About
            },
            {
                path: "contact",
                name: "Contact",
                menuName: "联系我们",
                component: Contact
            },
            {
                path: "product",
                name: "Product",
                menuName: "产品介绍",
                component: Product,
                children:[
                    {
                        path: "product00",
                        name: "Product00",
                        menuName: "医院综合收费智能管理核准系统",
                        component: Product00
                    },
                    {
                        path: "product01",
                        name: "Product01",
                        menuName: "医院综合收费智能管理核准系统",
                        component: Product01
                    },
                    {
                        path: "product02",
                        name: "Product02",
                        menuName: "医保基金管理智能审核系统",
                        component: Product02
                    },
                    {
                        path: "product04",
                        name: "Product04",
                        menuName: "新医云·云影像",
                        component: Product04
                    },
                    {
                        path: "product05",
                        name: "Product05",
                        menuName: "医疗科研项目",
                        component: Product05
                    },
                    {
                        path: "product10",
                        name: "Product10",
                        menuName: "技术运维服务",
                        component: Product10
                    },
                    {
                        path: "product11",
                        name: "Product11",
                        menuName: "智慧社区",
                        component: Product11
                    },
                    {
                        path: "product12",
                        name: "Product12",
                        menuName: "智慧监督",
                        component: Product12
                    }
                ]
            }
        ]
    }]

// 拦截当前路由跳转的错误信息
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const createRouter = () => new Router({
    routes: baseRouters
})

const router = createRouter()

export default router
