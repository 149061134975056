<template>
<div class="my-layout">
  <div class="box1">
    <div class="box1-title">产品介绍</div>
    <div class="box1-content">
      <p>新医云自主研发的远程影像诊断平台，融合了最先进的互联网、云计算、大数据、AI以及医学影像技术，为各级医级疗机构提供精准、高效、便捷的远程影像诊断服务。解决基层医院影像诊断医生不足的困局，降低人力成本，提高诊断效率，提升诊断能力，全面满足临床医生对影像的诊断需求。</p>
      <p>公司以医学影像为切入口，依托5G、云计算、大数据以及人工智能AI等高新尖技术，自主研发了远程影像、远程心电、远程超声、云胶片、医联体中心和专家云诊室六大产品体系，为全球医疗机构提供包含医学影像的采集、传输、存储、诊断、查询、管理在内的“智慧影像”一体化解决方案。</p>
    </div>
  </div>
  <div class="box2">
    <div class="box2-title">产品流程</div>
    <img class="box2-content" src="https://minio.xjiot.link/93album/fb62b91d-28fa-463c-8cc6-8580a05e6d91.png" alt=""/>
  </div>
  <div class="box box3">
    <div class="title">核心优势</div>
    <div class="content">
      <a-row :gutter="40">
        <a-col class="box3-col" :span="8"  v-for="item in advantages" :key="item.title">
          <div class="advantage">
            <div class="advantage-icon" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
            <div class="advantage-title">{{item.title}}</div>
            <div class="advantage-content">{{item.content}}</div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <div class="box box4">
    <div class="title">特色功能</div>
    <div class="content">
      <a-row :gutter="20">
        <a-col :span="6" v-for="item in characters" :key="item.title">
          <div class="character">
            <div class="character-icon" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
            <div class="character-title">{{item.title}}</div>
            <div class="character-content">{{item.content}}</div>
          </div>
        </a-col>
      </a-row>

    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ProductZF",
  data(){
    return{
      advantages:[
        {
          img:"b716359d-58bf-49da-9585-b91e50e1fd61.png",
          title:"精准",
          content:"顶尖影像专家团队阅片，采用三甲医院质控 流程，报告双签名加质控抽查制度，综合大 数据与合作医院反馈统计，报告准确率高达 99.5%"
        },
        {
          img:"7d263882-6c74-47a6-8b7b-c86618c2cc35.png",
          title:"快捷",
          content:"24小时医生在线值班，远程报告平均时间在 15分钟左右，急诊与门诊病人不超过30分钟 住院病人不超过60分钟"
        },
        {
          img:"e311f7cb-852f-44de-8de7-8d857bc41730.png",
          title:"高效",
          content:"先进“A1+5G”智慧云平台，电脑及移动设备均可使用，千张医疗影像数据秒级调阅，数据全生命周期保护不丢失"
        },
        {
          img:"3986326d-37af-4ecf-8a3c-390d06f81b64.png",
          title:"合规",
          content:"已获得医疗机构执业许可证，属于第三方远 程互联网医院，具备合法出具远程诊断报告 的资格，与新医云签约远程诊断服务，符合 远程医疗相关政策法规"
        },
        {
          img:"9e3dda54-5b8c-4ea1-9dac-302c03a9437d.png",
          title:"贴心",
          content:"执行客户即上帝的服务理念，运维客服团队 24小时在线，专人“一对一”精细化服务，及时处理合作医院的需求"
        },
        {
          img:"23736083-cda4-44ad-a602-f89c33c0dcb4.png",
          title:"便利",
          content:"合作医院零建设投入，安装培训一步到位，提供运营支撑服务。医院设备端无缝连接，20分钟即可完成配置"
        }
      ],
      characters:[
        {
          img: "5dda3970-6eba-4704-b2e4-5b318ae03c93.png",
          title: "助政府之策",
          content:"优化医疗资源配置，助力分级诊疗开展切实\n解决民生“看病难”“看病贵”等问题",
        },
        {
          img: "c2b25aa0-1590-496c-a50a-741de07d0f7f.png",
          title: "拓医生之才",
          content:"推动优质医生多点执业，释放专业\n搭建医学影像问诊共享、共研平台",
        },
        {
          img: "d27d7f21-b5ab-4379-9bf4-98f748c3c386.png",
          title: "破医院之困",
          content:"解决影像医生资源缺乏的困局\n降低运营成本，提升智能服务",
        },
        {
          img: "e9eeeb21-9fee-4b9f-a233-9b2e23703aa2.png",
          title: "解患者之难",
          content:"创建专业、便捷问医渠道\n节约患者就医成本及时间",
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.my-layout{
  background-color: #ffffff;
}

.box1{
  width: 100%;
  height: 521px;
  padding: 120px 0;
  background: #F9F9F9;
  text-align: center;
  &-title{
    font-weight: bold;
    font-size: 40px;
    color: #000000;
    line-height: 56px;
    text-align: center;
  }
  &-content{
    padding-top: 60px;
    max-width: calc(100vw - 484px);
    margin: 0 auto;
    font-weight: 400;
    font-size: 19px;
    color: #333333;
    line-height: 33px;
    font-style: normal;
    text-align: left;
    p+p{
      margin-top: 40px;
    }
  }
}

.box2{
  width: 100%;
  height: 1147px;
  padding: 120px 0;
  text-align: center;
  &-title{
    padding-bottom: 60px;
    font-weight: bold;
    font-size: 40px;
    color: #000000;
    line-height: 56px;
    text-align: center;
  }
  &-content{
    width: 1120px;
    margin: 0 auto;
  }
}

.box3{
  height: 1337px;
  background-image: url("https://minio.xjiot.link/93album/fd5543c2-c68f-4ebd-b993-a34a65b9dab7.png");
  .title{
    color: #ffffff;
  }
  .content{
    max-width: calc(100vw - 646px);
  }
  .advantage{
    margin: 0 auto;
    width: 400px;
    height: 470px;
    background: #FFFFFF;
    box-shadow: 0px 1px 44px 0px rgba(81,1,0,0.2);
    border-radius: 4px;
    padding: 65px 29px;
    text-align: left;
    &-icon{
      width: 108px;
      height: 108px;
      background-size: cover;
    }
    &-title{
      margin-top: 50px;
      font-weight: bold;
      font-size: 26px;
      color: #434343;
      line-height: 32px;
    }
    &-content{
      margin-top: 30px;
      font-weight: 400;
      font-size: 18px;
      color: #434343;
      line-height: 32px;
    }
  }
  .box3-col:nth-child(n+4){
    margin-top: 40px;
  }
}

.box4{
  height: 825px;
  .character{
    height: 470px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 43px 0px rgba(0,0,0,0.07);
    border-radius: 4px;
    &-icon{
      width: 56px;
      height: 56px;
      background-size: cover;
    }
    &-title{
      margin-top: 51px;
      font-weight: bold;
      font-size: 32px;
      color: #333333;
      line-height: 47px;
    }
    &-content{
      margin-top: 41px;
      font-weight: 400;
      font-size: 16px;
      color: #434343;
      line-height: 30px;
      word-break: break-all;
      white-space: pre-line;
    }
  }
}

</style>
