<template>
<div>
  <div class="box box1">
    <div class="title">项目介绍</div>
    <div class="content" :style="{marginLeft: (currentIndex*-66-(currentIndex*11))+'vw'}">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-content-title">水磨沟区2021年建设项目</div>
          <div class="intro-content-type">监控</div>
          <div class="intro-content-text">
            <p>
              对华光街片区管委会、龙盛街片区管委会、南湖北路管委会、七道湾片区管委会、石人子沟片区管委会等共八个管委会所辖的37个社区部署监控，新增摄像机，为平安城市接入视频监控系统平台。
            </p>
          </div>
        </div>
        <div class="intro-img" :style="{marginLeft: '60px',backgroundImage: `url('https://minio.xjiot.link/93album/37da8fb0-f117-4055-8f87-919c396830e6.png')`}"></div>
      </div>
      <div class="arrow-right" @click="arrow(1)" :style="{marginLeft: currentIndex===0? '4vw': '7vw'}">
        <a-icon
            :type="currentIndex===0?'arrow-right':'arrow-left'"
            :style="{color: '#ffffff',fontSize: '24px'}"></a-icon>
      </div>
      <div class="intro">
        <div class="intro-img" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/13a28335-0ebc-48e6-b0f5-b7beaa670f9d.png')`}"></div>
        <div class="intro-content" style="margin-left: 60px">
          <div class="intro-content-title">乌鲁木齐市（天山明月城工程）</div>
          <div class="intro-content-type">监控</div>
          <div class="intro-content-text">
            <p>
              在天山明月城T字形步行街的主街区、公共区域、商铺背后通道、休闲区、等布防视频监控前端系统，实现整个街区公共区域无死角覆盖。
            </p>
            <p>
              配置企业级硬盘本地存储，24小时不间断录像，以满足公安部门录像文件保存的管理要求。
            </p>
          </div>
        </div>
      </div>
      <div class="arrow-right" @click="arrow(2)">
        <a-icon :type="currentIndex===1?'arrow-right':'arrow-left'" style="color: #ffffff;font-size: 24px"></a-icon>
      </div>
      <div class="intro">
        <div class="intro-content">
          <div class="intro-content-title">乌鲁木齐市馕文化产业园弱电监控</div>
          <div class="intro-content-type">弱电监控</div>
          <div class="intro-content-text">
            <p>
              根据现场实际情况，在B3、B2、B1楼公共区域、过道、楼梯间等区域布放视频监控前端系统，B3、B2、B1整套安防监控系统点位，实现B3、B2、B1楼公共区域无死角覆盖。
            </p>
            <p>
              B3、B2、B1楼共配置企业级硬盘本地存储，24小时不间断录像，以满足公安部门录像文件保存的管理要求。
            </p>
          </div>
        </div>
        <div class="intro-img" :style="{marginLeft: '60px', backgroundImage: `url('https://minio.xjiot.link/93album/800b228f-db9f-48e8-b04a-f4e115956b15.png')`}"></div>
      </div>
      <div class="arrow-right" @click="arrow(3)">
        <a-icon :type="currentIndex===2?'arrow-right':'arrow-left'" style="color: #ffffff;font-size: 24px"></a-icon>
      </div>
      <div class="intro">
        <div class="intro-content">
          <div class="intro-content-title">水磨沟区产业园弱电工程项目控</div>
          <div class="intro-content-type">弱电工程</div>
          <div class="intro-content-text">
            <p>
              项目建设内容覆盖基地的项目计划建设7个工项目建设内容覆盖基地的项目计划建设7个工程系统，包括视频监控前端子系统、监控系统平台子系统、指挥中心子系统等。
            </p>
          </div>
        </div>
        <div class="intro-img" :style="{marginLeft: '60px', backgroundImage: `url('https://minio.xjiot.link/93album/ba098f48-c118-4af8-8e1c-305e0b15b4ec.png')`}"></div>
      </div>
    </div>
  </div>
  <div class="box box2">
    <div class="title">运维团队风采</div>
    <div class="content">
      <a-tabs :tabBarStyle="customTabBarStyle" :tabBarGutter="80">
        <a-tab-pane v-for="year in Object.keys(box2Tabs)" :key="year" >
          <div slot="tab">
            {{year}}<a-icon type="caret-down" style="font-size: 12px;margin-left: 7px;vertical-align: baseline"/>
          </div>
          <div class="timeline-year">{{year}}</div>
            <a-timeline style="margin-left: 120px;margin-top: 80px">
              <a-timeline-item v-for="(month, index) in Object.keys(box2Tabs[year])" :key="index">
                <div class="timeline-month">{{month}}</div>
                <div slot="dot" class="dot"></div>
                <div class="record">
                  <div class="record-left">
                    <div class="record-left__title">{{box2Tabs[year][month].title}}</div>
                    <div class="record-left__text">{{box2Tabs[year][month].content}}</div>
                  </div>
                  <div class="record-right" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${box2Tabs[year][month].img}')`}"></div>
                </div>
              </a-timeline-item>
            </a-timeline>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "productOZ",
  data(){
    return{
      currentIndex: 0,
      customTabBarStyle:{
        fontSize: '22px',
        color: '#FE1100',
        lineHeight:' 30px'
      },
      box2Tabs:{
        "2024年": {
          "5月": {
            title: "运维工程部众志成城，全力恢复水区监控在线率",
            content: "随着夏季旅游旺季的到来，水磨沟区监控系统的稳定运行成为了维护城市安全的重要任务。然而，由于连续的温度变化，部分监控设备出现了电力供应不稳定的情况，导致在线率下降，面对这一紧急情况，智翔科技运维工程部迅速响应，全体工程师团结一心，以高度的责任感和使命感，投入到紧张的抢修工作中。他们深知，每一次的故障排查、每一次的接电抢修，都直接关系到水磨沟区的安全稳定。",
            img: "ea370944-98c2-4981-b1a5-1f176ea37b87.png"
          },
          "4月":{
            title: "创新技术，提升设备维护效率",
            content: "运维工程部始终践行“拥抱变化，学习创新”的公司精神，不断创新技术解决方案，提升监控系统维护工作的高效性和稳定性。近期，运维工程师团队又有了新的创新应用：定时开关。" +
                "传统解决方案：平台总会有一些点位，因为卡死而掉线。工程师需要多次前往特定点位，对设备进行手动重启。不仅浪费了大量的时间和人力成本，还存在操作繁琐和影响设备寿命等问题。",
            img: "7fce5b19-5a0e-45d1-afe9-208902494764.png"
          },
          "3月":{
            title: "运维工程部众志成城，全力恢复水区监控在线率",
            content:"巡查与维护：斋月前，我们制定了详细的巡查计划，根据历史数据和安全风险评估，确定了各清真寺的重点监控点位，并安排专人进行巡查。巡查过程中，我们对监控设备的运行状态、录像质量、网络连接等进行了全面检查，并及时处理了一些潜在故障，确保视频监控在斋月期间的稳定运行。",
            img: "960ba050-625e-4828-a913-3d41aeca6ce4.png"
          },
          "1月": {
            title: "服务最后一公里一运维工程部年前工作保障",
            content: "在迎接新年的钟声即将敲响之际，为确保产品服务在新的一年中能够稳步发展，运维工程部全力投入年前保障工作。在过去的一个月中，运雄部在关键点位场所和重点卡口监控两个方面的巡检与维护，制定并采取了一系列有效措施。",
            img: "f14c466a-bf5b-4a32-8a08-d10722e4218c.png"
          }
        },
        "2023年": {
          "3月":{
            title: "运维工程部工作有序开展、稳步推进",
            content: "一、提高安全意识，坚持做好各项日常工作的前提下确保人身、财产安全。\n" +
                "二、进一步落实“日报告”和“零报告”制度，积极完善信息报送机制，做到每日跟踪、及时研判。及时分析运行情况、风险管控情况和工作存在的问题，为部门发展提供决策依据。\n" +
                "三、积极推动公司数字化运维的优化升级，配合公司研发部进行软件测试相关工作，为后续工作开展做好准备。\n" +
                "四、积极配合公司人力资源部门完成工作事项的督查与整改工作。\n",
            img: "2a1e886852d64ebca1c44e40e048b257.png"
          },
          "2月": {
            title: "强化培训管理，运维稳步提升",
            content: "运维工程部自春节后维护质量稳步提升，一方面，部门在公司领导指导下逐步建立和完善运维体系，使得运维管理体系和运维流程适应实际运维情况，另一方面，从每周部门例会中认真吸取工作中的教训和经验，并组织业务能力、工作态度方面的培训，从而提高部门员工的工作状态和技术能力。",
            img: "06ea7ee1c52f43ee8221f6ac738f490e.png"
          },
        },
        "2022年": {
          "12月": {
            title:"复工复产 | 运维工程部全力保障各项运维",
            content:"跨区公共交通恢复、机器轰鸣重回、街头人气聚集……随着全市疫情防控形势持续稳中向好，经济社会运行秩序加快恢复，作为数字化运维转型建设的主力军，运维工程部积极响应公司号召，有序推进复工复产。在确保疫情防控和安全运行的前提下，运维工程部在社区综治、警务站运行保障，前端卡口监控保障等方面持续发力，着力满足客户需求、提升客户感知，助力恢复正常运行，急客户之所急，集中火力解决客户的“疑难杂症”。\n",
            img: "25da37d0-f52c-4c87-80b6-6816b82b469c.png"
          },
          "9月": {
            title:"战“疫”中的运维人员，与你同在，共克时艰！",
            content:"运维工程部内部自上而下迅速启动疫情应急预案，运维人员依然时刻在线，远程解决故障、7x24h 运维保障、技术支持等一系列措施，共同守护水磨沟区。 疫情防控缓解期间，虽然停止了项目施工，但常态运维工作仍旧有序进行。一方面加强人员配置，在确保值班人员合理安排的基础上，积极做好应急准备。同时，做好每日值班人员健康情况上报。另一方面，数字化运维的软件测试工作也井然有序开展，运维工程部负责人挑选了部分业务骨干参与软件测试，为后续数字化运维更好的落地实施打下坚实基础。",
            img: "e80e948c-2513-408f-8fef-8a4f500a95e7.png"
          },
          "7月": {
            title:"箱体也有“身份证”了",
            content:"据了解，受地理环境影响，我司负责维护范围内部分杆号牌缺失、模糊，给线路的运行维护和检修留下安全隐患。为了保证维护人员现场操作时的人身、设备安全，不发生误操作，连日来，各小组开展杆体号牌清理排查工作。部门人员严格按照要求，对张贴后的二维码记录上传，并结合此项工作对线路、设备进行巡视，详细了解各街道设备运行情况，提前做好运维防范措施。",
            img: "a6c132cf-3bf0-4ed2-b629-1f7017a4d7dd.png"
          },
          "6月": {
            title:"凝心聚力 攻坚克难 各项工程稳步推进",
            content:"端午节小长假期间，运维工程部员工仍然坚守在施工一线。他们抓紧⼀切时间追赶施工进度，他们攻坚克难，发扬勇于拼搏的精神，凝心聚力奋战在施工现场，为项目的工程进度而努力，以饱满干劲和踏实的作风将工作干好，力争做表率\n",
            img: "0f0b3f7b-f171-47a5-9a2b-f1e4affb4326.png"
          },
          "5月": {
            title:"紧锣密鼓赶进度 热火朝天施工忙",
            content:"运维工程部接到项目施工任务后，迅速组织人员开展现场安装点位勘察、物资准备等工作，并进场开展项目实施工作。\n" +
                "项目紧急开启，在运维项目全面正常开展，馕文化产业园三期项目紧锣密鼓实施的同时，运维工程部快速进行人员分工调整，成立项目组积极协调各方资源，加班加点进行项目勘点及前期筹备工作。\n",
            img: "05358738-c101-4a17-a8dd-dc79e75203a3.png"
          },
          "4月": {
            title:"拓宽专业知识，提升自我",
            content:"为进一步保证部门内部人员的专业素质及实操水平，运维工程部于 4 月 15 日（周五）下午在公司会议室召集全体部门人员开展了内部业务培训课。\n" +
                "由运维工程部工程师陈宝分享运维工程理论基础知识。陈宝从实例出发，就容易忽视和难理解的专业理论结合自身的实践经验，进行深入浅出的讲解。\n",
            img: "c9357277-721a-4b12-a941-c3943de74212.png"
          },
          "3月": {
            title:"围绕培训促技能 抓好技能促培训",
            content:"为提高员工业务技能水平，丰富员工的生产理论知识，3月起，运维工程部组织本部门全体员工开展了日常运维故障分析及故障处理等一系列课程培训。\n" +
                "运维工程部同事们详细讲解了前端日常运维故障分析及故障处理的工作规程，使员工对前端日常运维工作有了更深入的掌握。\n",
            img: "f869700a-4a8a-42aa-9d66-0c6455f81758.png"
          }
        },
        "2021年": {
          "12月":{
            title: "鼓足干劲争上游，完美收官 2021",
            content: "落实不是无方法的蛮干，也不能光凭一腔热情，要掌握科学的思维方法，找寻落实的“金钥匙”。运维工程部大力弘扬“想干事、会干事、干成事”的“干事”文化，积极做好前端卡口摄像机维护、社区警务站设备维护、封闭化小区 SZW设备维护、社区拆搬迁、运维延展业务等工作，部门全体员工齐心协力，把项目进度往前赶，把事情往前干，做到心中有数，眼里有活，心无旁骛干好本职工作，对公司部署的工作，扭住不放、一抓到底，不断提升全员干事创业的劲头和能力，形成你追我赶、一马当先、勇往直前的大好趋势，确保完成全年目标任务，更要快完多超，以更快的速度、更高的标准，收好官、交好账，给一年的工作画上一个圆满的句号。",
            img: "fa6f44b5-3d64-4c10-adfb-b9df2f5f1f38.png"
          },
          "11月":{
            title: "加班加点赶工期 团结一致齐奋斗",
            content: "2021年11月初，我公司承接的项目施工现场呈现出一派热火朝天赶工期的场面，各施工小组为重要节点的工作任务紧张忙碌着。 因时间紧、任务重，项目经理督促各施工组按时进场，认真梳理施工采购计划、施工计划。为了顺利完成项目计划，运维工程部人员与施工组齐头并进，一同发扬“白加黑”的苦干实干精神，加班加点，坚守岗位，克服种种困难，战胜多种不利因素，积极推进项目进度。",
            img: "3bc6d894-089a-4664-9780-968648f03334.png"
          },
          "10月":{
            title: "投之以桃李，报之以琼浆",
            content: "我公司承担了重要的信息化建设内容，是保障方舱正常运行的基础工作。由于时间紧、任务重，为了让客户满意，我部门员工李鹏、樊万华在国庆期间加班加点顶着低温和雨雪天气的不利环境，依然克服困难奋斗在第一线，连续通宵工作一日又一日，协助客户完成了对本区域的重点场所信息化设备的安装工作。",
            img: "4c8eb39d-77e6-4fe1-8443-107f16e4d737.png"
          },
          "9月":{
            title: "“快、诚、实”推动客户服务“上水平",
            content: "在项目沟通过程中，营销人员对待客户以诚相待，开诚布公。在服务过程中积极接纳了客户的意见，积极的态度应对各类突发问题，客户提出的建议虚心采纳，以便在工作中更好的改进，不断完善服务，不断提高服务质量。\n" +
                "项目经理孔迪湘在项目中充分发挥了项目管理经验，发挥了吃苦耐劳的精神，不分假日的奋战在项目现场。\n",
            img: "d22a70c7-ae5b-41f8-bf5f-edcc0f84d914.png"
          }
        }
      }
    }
  },
  methods:{
    arrow(index){
      if(index>this.currentIndex){
        this.currentIndex += 1;
      }else{
        this.currentIndex -=1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box1{
  background: #F9F9F9;
  .content{
    //padding-left: 90px;
    width: 100%;
    max-width: unset;
    display: flex;
    position: relative;
    transform: translateZ(0);
    transition-duration: .6s;
  }

  .arrow-right{
    margin: auto 4vw;
    //padding: 0 13px;
    min-width: 3vw;
    height: 3vw;
    border-radius: 50%;
    background: #FE1100;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .intro{
    display: flex;
    justify-content: space-between;
    max-width: 67vw;
    min-width: 67vw;
    min-height: 526px;
    &-img{
      min-width: 412px;
      height: 526px;
      background-size: cover;
    }

    &-content{
      text-align: left;
      &-title{
        font-weight: 500;
        font-size: 27px;
        color: #000000;
        line-height: 34px;
      }
      &-type{
        margin-top: 60px;
        font-weight: bold;
        font-size: 40px;
        color: #FE1100;
        line-height: 44px;
      }
      &-text{
        margin-top: 60px;
        font-weight: 400;
        font-size: 20px;
        color: #333333;
        line-height: 38px;
        text-align: justify;

        p+p{
          margin-top: 15px;
        }
      }
    }
  }
  .intro:first-child{
    margin-left: 4vw;
  }
}


 .box2{
   background-color: #ffffff;
   .content{
     text-align: left;
     position: relative;
   }
   .dot{
     width: 11px;
     height: 11px;
     background: #FE1100;
     border-radius: 50%;
   }

   .timeline-year{
     position: absolute;
     top: 70px;
     left: 0;
     font-weight: 500;
     font-size: 32px;
     color: #FE1100;
     line-height: 45px;
   }

   .timeline-month{
     margin-left: -80px;
     font-weight: 500;
     font-size: 20px;
     color: #454545;
     line-height: 28px;
   }
    .record{
      margin : -60px 0 60px 50px;
      padding: 30px;
      min-height: 250px;
      background: #F2F4F6;
      display: flex;
      justify-content: space-between;
      &-left{
        &__title{
          font-weight: 500;
          font-size: 20px;
          color: #000000;
          line-height: 28px;
        }
        &__text{
          margin-top: 18px;
          font-weight: 400;
          font-size: 15px;
          color: #565656;
          line-height: 26px;
          text-align: justify;
        }
      }
      &-right{
        margin-left: 88px;
        min-width: 276px;
        min-height: 163px;
        background-size: cover;
      }
    }
   ::v-deep .ant-tabs-nav-scroll{
     text-align: center;
   }
 }
::v-deep .ant-tabs-tab-active{
  color: #FE1100!important;
}
::v-deep .ant-tabs-ink-bar{
  background-color: #FE1100!important;
}
::v-deep .ant-tabs-tab:hover{
  color: #FE1100!important;
}

::v-deep .ant-tabs-nav-container{
  font-weight: 500;
  font-size: 22px;
  color: #FE1100;
  line-height: 30px;
}
</style>
