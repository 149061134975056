<template>
  <div class="my-layout">
    <div class="box box1">
      <div class="title">产品背景</div>
      <div class="content">
        <p>《国家医保局 最高人民检察院 公安部 财政部 国家卫生健康委关于开展医保领域打击欺诈骗保专项整治工作的通知》2023年4月28日发布</p>
        <p>通知中强调，要强化大数据监管。国家医保局将开展大数据监管试点，通过“虚假住院”“医保药品倒卖”等大数据模型筛查可疑线索，并下发各地核查。各地要坚持规定动作与自选动作相结合，一方面认真完成国家下发的核查任务，逐条核查、逐条反馈、逐级上报；另一方面可结合当地实际，积极开展大数据监管，有针对性的开展筛查分析。</p>
      </div>
    </div>
    <div class="box box2">
      <div class="title">主要模式</div>
      <div class="content">
          <div class="model" v-for="item in box2Content" :key="item">
            <div class="square"></div>
            <div class="model-title">{{item}}</div>
          </div>
      </div>
    </div>
    <div class="box box3">
      <div class="title">标准化作业流程</div>
      <div class="content">

      </div>
    </div>
    <div class="box box4">
      <div class="title">系统优势</div>
      <div class="content">
        <a-row :gutter="390">
          <a-col :span="12" v-for="item in box4Content" :key="item.title">
            <div class="advantage">
              <div class="advantage-icon" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
              <img class="advantage-title" alt="" :src="`https://minio.xjiot.link/93album/${item.title}`" />
              <!--          <div class="advantage-title" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.title}'`}"></div>-->
              <div class="advantage-text">
                {{item.text}}
              </div>

            </div>
          </a-col>
        </a-row>
<!--        <div class="advantage">-->
<!--          <div class="advantage-icon" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>-->
<!--          <img class="advantage-title" alt="" :src="`https://minio.xjiot.link/93album/${item.title}`" />-->
<!--&lt;!&ndash;          <div class="advantage-title" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.title}'`}"></div>&ndash;&gt;-->
<!--          <div class="advantage-text">-->
<!--            {{item.text}}-->
<!--          </div>-->

<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productZT",
  data(){
    return{
      box2Content:["国家飞行检查","省内飞行检查", "地市全覆盖检查", "各类专项检查", "举报线索等核查"],
      box4Content:[
        {
          img:"9c9b3fb1-8fb2-4c63-8df2-9c15039e7f8d.png",
          title:"446fd8d2-7fc8-4936-bbf3-cb7db4dcde68.png",
          text:"100G数据，二小时以内采完，远超市场 同类产品"
        },
        {
          img:"03ef522c-2363-4304-9fe0-19b6c5e3a3b0.png",
          title:"a16bf877-85ef-4fbd-9ea6-e8d94782a127.png",
          text:"CSV 、DMP 、Bak等多数据处理格式"
        },
        {
          img:"136f78e1-3edb-4f8e-a088-c0cc460729c9.png",
          title:"05a5788e-35c2-499b-a07f-1372c1418895.png",
          text:"硬件设备携带方便，规则灵活调整、现场自助分析"
        },
        {
          img:"385a1d96-c26c-410c-b9b9-5bea9a775253.png",
          title:"72692c60-594d-4053-96bb-0c55926530bc.png",
          text:"规则跑批、数据探查、 自动报告"
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.my-layout{
  background-color: #ffffff;
}

.box1{
  height: 519px;
  .content{
    text-align: left;
    font-weight: 400;
    font-size: 19px;
    color: #333333;
    line-height: 33px;
    text-align: left;
  }
}
.box2{
  height: 496px;
  background-image: url("https://minio.xjiot.link/93album/ab70d6db80be43edbdbb4471bf84c367.png");
  background-size: cover;
  .title{
    color: #ffffff;
  }

  .content{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .model{
    position: relative;
    width: 237px;
    height: 140px;
    background: #666866;
    display: flex;
    justify-content: center;
    align-items: center;

    .square{
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background: #FE1100;
    }
    &-title{
      font-weight: 400;
      font-size: 25px;
      color: #FFFFFF;
      line-height: 34px;
    }
  }
  .model+.model{
    margin-left: 35px;
  }
}

.box3{
  height: 1505px;
  .content{
    height: 1149px;
    background-image: url("https://minio.xjiot.link/93album/96328c98-408f-4a9f-841c-04392cd05275.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
  }
}

.box4{
  height: 1203px;
  background-color: #F9F9F9;
  .content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .advantage{
    margin: 0 auto;
    width: 366px;
    height: 336px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    border-bottom: 2px solid #FE1100;
    &-icon{
      width: 66px;
      height: 66px;
      background-size: cover;
    }
    &-title{
      margin-top: 30px;
      //width: 80%;
      height: 30px;
    }
    &-text{
      margin-top: 40px;
      font-weight: 400;
      font-size: 21px;
      color: #000000;
      line-height: 50px;
      text-align: left;
    }
  }
  .advantage:nth-child(2n){
    margin-left: 390px;
  }
  .advantage:nth-child(n+3){
    margin-top: 100px;
  }
  .ant-col:nth-child(n+3){
    margin-top: 100px;
  }
}

</style>
