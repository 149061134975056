<template>
  <div>
    <div class="box box1">
      <div class="warp">
        <div class="title">智慧监督大平台</div>
        <div class="content">
          <p>即前台功能，以互联网为主要载体进行公示公开，主要对2018年以来阜康市发布的相关政策及各单位、乡镇（街道）的惠民惠农资金发放、乡村振兴项目实施情况，以及村（社区）的收入、支出，资源、资产、村（居）务等内容进行公示公开。</p>
          <p>即后台功能，以昌吉州纪委监委信息中心云计算为主要载体进行全流程监管，录入公职人员、房产、车辆等基础数据，设置数据分析功能，该功能体系包含基础数据模块、预警模型、数据统计分析、问题数据分类处置、纪实考核5大功能。通过大数据分析比对提示预警，发现公职人员违规违纪违法问题线索。</p>
        </div>
      </div>
    </div>
    <div class="box box2">
      <div class="warp">
        <div class="title">平台介绍</div>
        <div class="content">
          <a-row :gutter="36">
            <a-col :span="12" v-for="(item, index) in box2" :key="index">
              <div class="introduce">
                <div class="introduce-seria" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
                <div class="introduce-title">{{item.title}}</div>
                <div class="introduce-content">{{item.content}}</div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="warp">
        <img class="box3-item" src="https://minio.xjiot.link/93album/467787b104334a7db4e8bbb8ca8d2f85.png" alt=""/>
        <img class="box3-item" src="https://minio.xjiot.link/93album/7256a9a2055e4b9889e6d38aba472246.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productOT",
  data(){
    return{
      box2:[
        {
          title: "基础数据模块",
          content:"11类基础数据：公职人员数据、房产数据、车辆数据、已故人员数据、村（社区）干部数据、工商数据、党员数据、残疾人数据、行政处罚数据、失信人员、招标公示。",
          img: "b4f91732d7f54904853aea71fe58c209.png"
        },
        {
          title: "预警模型",
          content:"系统通过对基础数据的对比分析，可自定义生成多种数据模型作为预警模型。当前预警模型包括：资金分类-房产车辆；残疾人资格审查；失信人员-公职人员；失信人员-中共党员等。\n",
          img: "d89d8fbe64064178be605a2f6c0618af.png"
        },
        {
          title: "纪实考核",
          content:"平台设置数据核查监督管理机制，规范各市直部门、乡镇（街道）及时、按时更新数据，对多次未按时更新数据信息的部门、乡镇（街道）进行纪实考核。",
          img: "4f95b3cd0d07456284cb9f7f278a087e.png"
        },
        {
          title: "问题数据分类模型",
          content:"通过后台数据比对，将筛选出的疑点问题进行分类处置。属于纪检监察业务范围内的线索，按照相关规定转办致四个协作区进行调查核实；属于纪检监察业务范围外的投诉等问题，依据规定移交相关职能部门核实答复。\n",
          img: "d054bad8e9fa4edb97ced30f3d4111d8.png"
        },
        {
          title: "数据设计分析模型",
          content:"建立系统分析模型，按照统计分析模型（如资金主管部门、资金类别、受益区域、覆盖人群等 ）进行大数据可视化分析，以饼状图 、柱状图、折线图等形式进行直观展示，为党风廉政建设提供数据依据。",
          img: "d93cfbb76bb148db9ae139ff11c9235c.png"
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.box1{
  height: 542px;
  background-color: #ffffff;
}

.box2{
  height: 958px;
  background-color: #F9F9F9;
  .introduce{
    position: relative;
    width: 100%;
    height: 184px;
    background: #FFFFFF;
    box-shadow: 0px 2px 43px 0px rgba(0,0,0,0.07);
    border-radius: 4px;
    padding: 31px;
    &-seria{
      position: absolute;
      z-index: 1;
      right: 25px;
      top: 15px;
      width: 35px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &-title{
      font-weight: 500;
      font-size: 20px;
      color: #000000;
      line-height: 28px;
      text-align: left;
    }
    &-content{
      margin-top: 18px;
      font-weight: 400;
      font-size: 15px;
      color: #565656;
      line-height: 26px;
      text-align: justify;
    }
  }

  .ant-col:nth-child(n+3){
    margin-top: 30px;
  }
}

.box3{
  height: 843px;
  width: 100vw;
  background-image:  url("https://minio.xjiot.link/93album/0b5bfac18dbe4254923e00edea041507.png");
  background-size: cover;
  background-repeat: no-repeat;
  .warp{
    margin: 0 auto;
    max-width: calc(100vw - 484px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .box3-item{
      width: 330px;
      height: 605px;
    }
    .box3-item+.box3-item{
      margin-left: 205px;
    }
  }
}


</style>
