<template>
  <div class="my-layout">
    <div class="box box1">
      <div class="title">产品概述</div>
      <div class="content">
        医院综合收费智能管理核准系统 是智翔科技倾力研发的智能医保整体解决方案，基于DRG支付方式的改革，综合分析医院的产能与效率、质量与发展、均衡医保与控费监管等多重分析，通过事前、事中、事后三个环节，对全院-科室-人员三级角色进行指标及医保费用情况分析，为医院运营管理决策提供重要的数据支撑。帮助医院在保证医疗服务质量的基础上，更好的开展DRG支付改革工作，实现对院内医保的监管与长效发展。
      </div>
    </div>
    <div class="box box2">
      <div class="title">产品特点</div>
      <div class="content">
        <div class="block" v-for="item in box2Content" :key="item">
          <div class="block-icon"></div>
          <div class="block-text">{{item}}</div>
        </div>
      </div>
    </div>
    <div class="box box3">
      <div class="title">业务场景</div>
      <div class="content">
        <a-row :gutter="40">
          <a-col class="box3-col" :span="8"  v-for="item in advantages" :key="item.title">
            <div class="advantage">
              <div class="advantage-icon" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
              <div class="advantage-title">{{item.title}}</div>
              <div class="advantage-content">{{item.content}}</div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="box box4">
      <div class="title">DRG运营监管系统</div>
      <div class="content">
        <a-row class="box4-row" :gutter="10" v-for="i in [0,1,2,3,4]" :key="i">
          <a-col :span="6" v-for="j in [0,1,2,3]" :key="j">
            <div class="block">{{box4Content[i][j]}}</div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productZO",
  data(){
    return{
      advantages:[
        {
          img:"1b02db12-2bdf-4417-91b7-cba61a8ba11e.png",
          title:"DRG分组",
          content: "系统提供与国家医保局统一的CHS-DRG分组方案，在院内部署分组器，并提供智能分组方案，极大程度提升入组率。提供医保结算清单上传接口，更好的质控上传数据质量。"
        },
        {
          img:"9d8b1289-bcb6-48cd-9954-0cddb4b0a29c.png",
          title:"医保规则弹窗",
          content:"从医嘱源头开始建立医保规则辅助及指标预警，以指引医疗行为更加符合医保相关政策，帮助医院由被动变为主动管理，合理规范诊疗路径。"
        },
        {
          img:"e311f7cb-852f-44de-8de7-8d857bc41730.png",
          title:"数据运营分析",
          content: "提供基于大数据分析下的全院数据及科室角度分析与标杆费用的盈亏分析，为DRG模拟运行背景下提供费用决策分析功能，应对DRG付费来临以及医院运营的精细化管理"
        }
      ],
      box2Content:["深入监控医保费用使用过程", "智能辅助分组", "支持“多分组器”", "多维度/全方面分析", "全院病例管理","异常检测", "医保规则智能监督", "医保结算全流程管理"],
      box4Content:[
        ["在院运行监管", "离院患者管理", "医保费用盈亏分析", "医保算管理"],
        ["预分组", "智能入组分析", "盈亏分析", "结算单导入"],
       [ "智能辅助入组", "患者费用分析", "趋势分析", "医保结算清单质控"],
        ["同组病例对比", "同组病理对比", "多维度分析", "特病单议预测"],
       ["智能监测预警", "智能监测预警", "费用结构分析", "医保申诉"],
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.my-layout{
  background-color: #ffffff;
}

.box1{
  .content{
    font-weight: 400;
    font-size: 19px;
    color: #333333;
    line-height: 33px;
    text-align: left;
  }
}

.box2{
  height: 460px;
  background: #F9F9F9;
  .content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .block:nth-child(n+6){
      margin-top: 30px;
    }
    .block{
      width: fit-content;
      min-width: 240px;
      height: 74px;
      padding: 25px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: linear-gradient( 180deg, #FFEEEE 0%, #FFFFFF 100%);
      box-shadow: 0px 16px 32px 0px rgba(236,210,210,0.4);
      border-radius: 4px;
      &-icon{
        width: 24px;
        height: 24px;
        background-image: url("https://minio.xjiot.link/93album/9f4cc29e-9174-44cc-9f02-9d6a8d5b70f2.png");
      }
      &-text{
        margin-left: 12px;
        font-weight: 400;
        font-size: 20px;
        color: #16181A;
        line-height: 28px;
      }
    }
    .block+.block{
      margin-left: 20px;
    }
  }
}

.box3{
  height: 826px;
  background-image: url("https://minio.xjiot.link/93album/fd5543c2-c68f-4ebd-b993-a34a65b9dab7.png");
  background-size: cover;
  .title{
    color: #ffffff;
  }
  .content{
    max-width: calc(100vw - 646px);
  }
  .advantage{
    width: 400px;
    height: 470px;
    background: #FFFFFF;
    box-shadow: 0px 1px 44px 0px rgba(81,1,0,0.2);
    border-radius: 4px;
    padding: 65px 29px;
    text-align: left;
    &-icon{
      width: 108px;
      height: 108px;
      background-size: cover;
    }
    &-title{
      margin-top: 50px;
      font-weight: bold;
      font-size: 26px;
      color: #434343;
      line-height: 32px;
    }
    &-content{
      margin-top: 30px;
      font-weight: 400;
      font-size: 18px;
      color: #434343;
      line-height: 32px;
    }
  }
  .box3-col:nth-child(n+4){
    margin-top: 40px;
  }
}

.box4{
  height: 706px;
  .block{
    padding: 17px 0;
    background: linear-gradient( 180deg, #FFC5C5 0%, #FFEFEF 100%);
    font-weight: 400;
    font-size: 19px;
    color: #642E2E;
    line-height: 28px;
    text-align: center;
  }
  .box4-row:nth-child(2n){
    .block{
      background: #EEEEEE!important;
      font-weight: 400;
      font-size: 19px;
      color: #141414;
      line-height: 28px;
    }
  }
  .box4-row+.box4-row{
    margin-top: 10px;
  }

}
</style>
