<template>
  <div>
    <div class="box box1">
      <div class="title">产品概述</div>
      <div class="content">
        <p>
          以医保为切入点，充分利用信息化手段将医保政策性要求和规范贯穿于诊疗服务的全过程，围绕“三合理一规范”，帮助医院从医嘱源头开始建立医保规则辅助及指标预警，以指引医疗行为更加符合医保相关政策，帮助医院由被动变为主动管理，合理规范诊疗路径，促进医-保-患三方利益平衡。 </p>
        <p>
          为医院提供事前、事中、事后审核，覆盖医院整个医保经办业务流程和场景。使用人工智能技术和临床知识库、医保规则库、DRG/DIP分组规则，对病案首页、医保结算清单、诊疗过程数据进行全方位的质控和监管。及时推送提醒，筛查医保违规使用和操作行为，协助医院内部医保管理部门应对飞行检查，加强医保政策风险控制、减少医保违规行为。</p>
      </div>
    </div>
    <div class="box box2">
      <div class="title">质控数据管理</div>
      <div class="content">
        <a-row :gutter="30">
          <a-col class="box2-col" :span="8" v-for="item in box2Content" :key="item.title">
            <div class="block">
              <div class="block-title">
                <div class="block-title__icon" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
                <div class="block-title__text">{{item.title}}</div>
              </div>
              <div class="block-line"></div>
              <div class="block-content">{{item.content}}</div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="box box3">
      <div class="title">主要解决</div>
      <div class="content">
        <div class="block" v-for="item in box3Content" :key="item">
          <div class="block-icon"></div>
          <div class="block-text">{{item}}</div>
        </div>
      </div>
    </div>
    <div class="box box4">
      <div class="title">系统流程</div>
      <div class="content">
        <img src="https://minio.xjiot.link/93album/22e56fbb-3aa5-4831-9bcd-2504ce57b869.png" alt="" style="width: auto;height: 100%"/>
      </div>
    </div>
    <div class="box box5">
      <div class="title">全流程管理</div>
      <div class="content">
        <a-row :gutter="40">
          <a-col class="box3-col" :span="8"  v-for="item in advantages" :key="item.title">
            <div class="advantage">
              <div class="advantage-icon" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
              <div class="advantage-title">{{item.title}}</div>
              <div class="advantage-content">{{item.content}}</div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productZZ",
  data(){
    return{
      box2Content:[
        {
          img: "3519833d-ab76-44c6-af4f-4467538dc9e6.png",
          title: "数据管理和维护",
          content: "确保各类患者信息得到全面、准确、完整地收集和记录"
        },
        {
          img: "27e02c48-b6e3-47e8-9690-14eded99eef9.png",
          title: "质量控制",
          content: "确保诊疗路径的正确性和可靠性"
        },
        {
          img: "36beb9af-f617-4816-acf4-a0aa86e3df1b.png",
          title: "规则库的验证和更新",
          content: "确保其符合最新的医保规则，并能够反映不同患者群体的特点和需求"
        },
        {
          img: "fcc46497-d0da-4c19-bc2a-6357edfdf2f7.png",
          title: "审核流程的规范化",
          content: "确保审核结果的准确性和可靠性"
        },
        {
          img: "6ed9e992-88cf-44e7-8833-d79e3f7ab8cd.png",
          title: "管理的精细化",
          content: "确保医疗机构和医保管理部门能够对临床工作进行有效的管理和监督"
        },
        {
          img: "a01dd12f-b72b-4f11-b279-16415155278f.png",
          title: "性能评估和调整",
          content: "确保其在实践中能够达到预期的效果并不断提高，及时发现问题并进行改进"
        }
      ],
      box3Content:["重复收费", "串换药品", "过度诊疗", "超医保范围", "分解住院", "其他问题"],
      advantages:[
        {
          img:"29c426c1-fadb-49b8-9449-a876a755e3c8.png",
          title:"实时事中入组提示",
          content: "书写病历文书的过程中实时提醒预测入组信息，不需要点击保存就可以自动弹窗提醒"
        },
        {
          img:"dabadd7e-df1f-4dac-ae60-4d0c35b85b28.png",
          title:"合理性风险提示",
          content: "支持30天再入院、住院天数超长、低风险死亡等风险进行提示，并支持医院自定义"
        },
        {
          img:"e4ca8839-9bc9-4553-932e-acffa52f434c.png",
          title:"违规行为分析",
          content: "对所有存在违规行为的规则的违规次数占比、费用占比等行为进行快速查询及数据导出"
        }
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.box2{
  height: 866px;
  background-image: url("https://minio.xjiot.link/93album/6d8ac9ed-2293-4009-9bc6-e8cb44d9b903.png");
  background-size: contain;
  .title{
    color: #ffffff;
  }
  &-col:nth-child(n+4){
    margin-top: 30px;
  }
  .block{
    height: 240px;
    padding: 26px;
    background: rgba(255,255,255,0.14);
    box-shadow: 0px 12px 44px 0px rgba(96,0,0,0.15);
    border-radius: 2px;
    border: 1px solid #FFFFFF;
    filter: blur(0px);
    text-align: left;

    &-title{
      display: flex;
      align-items: flex-end;
      &__icon{
        width: 42px;
        height: 42px;
        background-size: contain;
      }
      &__text{
        margin-left: 4px;
        font-weight: 500;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 36px;
      }
    }
    &-line{
      margin-top: 30px;
      width: 318px;
      height: 1px;
      background: #ffffff;
    }
    &-content{
      margin-top: 30px;
      font-weight: 500;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 27px;
    }
  }
}


.box3{
  height: 430px;
  background: #F9F9F9;
  .content{
    display: flex;
    justify-content: center;

    .block{
      width: fit-content;
      height: 74px;
      padding: 25px 44px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: linear-gradient( 180deg, #FFEEEE 0%, #FFFFFF 100%);
      box-shadow: 0px 16px 32px 0px rgba(236,210,210,0.4);
      border-radius: 4px;
      &-icon{
        width: 24px;
        height: 24px;
        background-image: url("https://minio.xjiot.link/93album/9f4cc29e-9174-44cc-9f02-9d6a8d5b70f2.png");
      }
      &-text{
        margin-left: 12px;
        font-weight: 400;
        font-size: 20px;
        color: #16181A;
        line-height: 28px;
      }
    }
    .block+.block{
      margin-left: 20px;
    }
  }
}

.box4{
  height: 926px;
  background-color: #ffffff;
  .content{
    text-align: center;
    //max-width: 1291px;
    height: 570px;
    //background-image: url("https://minio.xjiot.link/93album/22e56fbb-3aa5-4831-9bcd-2504ce57b869.png");
    //background-size: cover;
  }
}

.box5{
  height: 663px;
  background-image: url("https://minio.xjiot.link/93album/ab70d6db80be43edbdbb4471bf84c367.png");
  background-size: contain;
  .title{
    color: #ffffff;
  }
  .content{
    max-width: calc(100vw - 646px);
  }
  .advantage{
    height: 307px;
    background: #FFFFFF;
    box-shadow: 0px 1px 44px 0px rgba(81,1,0,0.2);
    border-radius: 4px;
    padding: 42px 31px;
    text-align: left;
    &-icon{
      width: 52px;
      height: 52px;
      background-size: cover;
    }
    &-title{
      margin-top: 42px;
      font-weight: 500;
      font-size: 26px;
      color: #333333;
      line-height: 46px;
    }
    &-content{
      margin-top: 22px;
      font-weight: 400;
      font-size: 16px;
      color: #878787;
      line-height: 29px;
    }
  }
  .box5-col:nth-child(n+4){
    margin-top: 40px;
  }
}

</style>
